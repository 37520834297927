.carousel-container {
    position: relative;
    width: 100%;
}

/* Adjust arrow position */
.react-multi-carousel-arrow-prev,
.react-multi-carousel-arrow-next {
    position: absolute;
    top: 50%;
    margin-left: 100%;
    background: transparent;
    border: none;
    cursor: pointer;
    z-index: 1; /* Ensure arrows are above the video player */
}

.react-multi-carousel-arrow-prev {
    left: 0;
}

.react-multi-carousel-arrow-next {
    right: 0;
}

/* Adjust arrow size */
.react-multi-carousel-arrow-prev::before,
.react-multi-carousel-arrow-next::before {
    font-size: 24px;
    color: #000; /* Adjust arrow color as needed */
}
.video-wrapper {
    position: relative;
    overflow: hidden;
    transition: transform 0.3s ease-out;
}

.video-player {
    transition: transform 0.3s ease-out;
}

.video-wrapper:hover .video-player {
    transform: scale(1.05); /* Adjust the scale value for the desired zoom effect */
}
