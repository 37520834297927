
  
ul{
    display: flex;
    flex-direction: row;
    margin-top: 10px;
    margin-bottom: 12px;
    align-items: center;
    justify-content: space-between;
}
  
  ul li a {
    width:40px;
    height: 40px;
    background-color: #212529;
    text-align: center;
    line-height: 38px;
    font-size:18px;
    right: 35px;
   
    display: block;
    align-items: center;
    border-radius: 50%;
    position: relative;
    overflow: hidden;
    border: 2px solid #fff;
    z-index: 1;
  }
  
  ul li a .icon {
    position: relative;
    color: #d2cbcb;
    transition: .5s;
    z-index: 3;
  }
  
  ul li a:hover .icon {
    color: #fff;
    transform: rotateY(360deg);
  }
  
  ul li a:before {
    content: "";
    position: absolute;
    top: 100%;
    left:0;
    right: 12%;
    width: 100%;
    height: 100%;
    background: #f00;
    transition: .5s;
    z-index: 2;
  }
  
  ul li a:hover:before {
    top: 0;
  }
  
  ul li:nth-child(1) a:before{
    background: #f00;
  }
  
  ul li:nth-child(2) a:before{
    background: #0e952d;
  }
  
  ul li:nth-child(3) a:before {
    background: #0077b5;
  }
  
  ul li:nth-child(4) a:before {
    background: rgb(231, 3, 79);
  }