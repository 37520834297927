section {
    text-align: center;
    margin-top: 2.5%;
  }
  .scroll-arrow {
    width: 10%;
      display: inline-block;
      position: relative;
      vertical-align: middle;
      cursor: pointer;
      padding:0;
      margin:0;
      opacity: 0.5;
    font-size:34px;
    transition: 0.2s;
  }
  .scroll-arrow:hover {
    transition: 0.2s;
    opacity:1;
  }
.ul {
      width: 100%;
      scroll-behavior: smooth;
      display: inline-block;
      position: relative;
      vertical-align: middle;
      text-align: center;
      overflow-x: scroll;
      overflow-y: hidden;
      white-space: nowrap;
      -webkit-overflow-scrolling: touch;
    }
      ul::-webkit-scrollbar {
      display: none;
    }
    .li {
      list-style: none;
      display: inline-block;
      position: relative;
      vertical-align: middle;
      width: 30%;
      margin-left: 1.5%;
      margin-right: 1.5%;
      border-radius: 10px;
      padding: 10px;
    }
  .li {
    width: 300px;
  }
  .img{
    width: 260px;
    cursor: pointer;
    border-radius: 10px;
    height: 300px;
  }