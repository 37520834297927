.home-popup {
  position: fixed;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  z-index: 1205;
  background-color: rgba(0, 0, 0, 0.5);
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.5);
}

.popup {
  position: relative;
  border-radius: 8px;
  padding: 20px;
}

.popup-content {
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
}



.close-popup-btn {
  position: absolute;
  top: 8.3%;
  right: 12.3%; /* Position it outside the image */
  transform: translateY(-50%);
  background-color: #e8b008;
  color: rgb(41, 9, 9);
  border: none;
  padding: 5px 10px;
  border-radius: 4px;
  cursor: pointer;
  font-weight: 700;
}
.close-popup-mobile{
  position: absolute;
  top: 5%;
  right: 5%; /* Position it outside the image */
  transform: translateY(-50%);
  background-color: #efcb15;
  color: white;
  border: none;
  padding: 5px 10px;
  border-radius: 4px;
  cursor: pointer;
}
